import { useContext, useState } from "react";
import styles from "./Navbar.module.scss";
// import Button from '../../Common/Button/Button';
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import AppContext from "../../../context/AppContext";
import Button from "../Button/Button";
import ContactForm from "../Contact/Contact";
import Modal from "../Modal/Modal";
const Navbar = () => {
  const router = useRouter();
  const isHomePage = router.pathname === "/";
  console.log({ isHomePage });

  const [showMenu, setShowMenu] = useState(false);
  const { contactForm, setContactForm }: any = useContext(AppContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  // Close dropdown when leaving the list item
  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };
  // Close dropdown when clicking a menu item
  const handleItemClick = () => {
    setIsDropdownOpen(false);
  };
  const solutionList = [
    {
      id: 1,
      img: "/b2b-white.png",
      title: "B2B Stack",
      description: "",
      path: "/b2b-stack",
    },
    {
      id: 2,
      img: "/b2c-new.png",
      title: "B2C & D2C Stack",
      description: "",
      path: "/b2c-d2c-stack",
    },
    {
      id: 3,
      img: "/social-netwrok.png",
      title: "Social Commerce Stack",
      description: "",
      path: "/social-commerce",
    },
    {
      id: 4,
      img: "/generative-ai.png",
      title: "Generative AI Wrappers",
      description: "",
      path: "/generative-ai",
    },
    {
      id: 5,
      img: "/new-solution.png",
      title: "Headless Solutions",
      description: "",
      path: "/product-stack",
    },
    {
      id: 6,
      img: "/commerce-api.png",
      title: "Commerce Apis",
      description: "",
      path: "/commerce-apis",
    },
    {
      id: 7,
      img: "/oms.png",
      title: "Order Management Solution",
      description: "",
      path: "/oms-solutions",
    },
  ];

  const priceEngineList = [
    {
      id: 1,
      img: "/etail-new-icon.png",
      title: "eTail Data Lake",
      description: "",
      path: "/eTail-data-lake",
    },
    {
      id: 2,
      img: "/new-solution.png",
      title: "Headless Price Engine",
      description: "",
      path: "/headless-price-engine-ai",
    },
    {
      id: 3,
      img: "/trend-new.png",
      title: "Trend Analyzer",
      description: "",
      path: "/trend-analyzer",
    },
  ];
  return (
    <nav className={styles["navbar-wrapper"]}>
      <div className={styles.navbar}>
        {/* <Link href={'/'}> */}
        <div className={styles.logoContainer}>
          <Link href={"/"} style={{ display: "flex", alignItems: "center" }}>
            <img src="/logo.svg" alt="logo" style={{ width: 25, height: 25 }} />
            <p className={styles.logoText}>socommerz</p>
          </Link>
        </div>
        {/* </Link> */}

        <div>
          <ul className={styles.list}>
            <li className={styles.listItem}>
              <Link href={"about-us"}>About</Link>
            </li>

            <li
              className={styles.listItem}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Solutions<span>&#8964;</span>
              <ul className={styles["dropdown"]}>
                <div className={styles["dropdown-container"]}>
                  {solutionList?.map((item, index) => (
                    <li
                      className={`${styles["dropdown-item"]}`}
                      key={index + item?.id}
                      onClick={handleItemClick}
                    >
                      <Link
                        href={item.path}
                        className={styles["dropdown-item-detail"]}
                      >
                        <div className={styles["dropdown-item-detail-img"]}>
                          <Image
                            height={18}
                            width={24}
                            alt={`solution-icon`}
                            src={item?.img}
                            className={styles["img"]}
                          />
                        </div>
                        <div className={styles["dropdown-item-detail-content"]}>
                          <p className={styles["dropdown-item-detail-title"]}>
                            {item?.title}
                          </p>
                          <p className={styles["dropdown-item-detail-des"]}>
                            {item?.description}
                          </p>
                        </div>
                      </Link>
                    </li>
                  ))}
                </div>
              </ul>
            </li>
            <li
              className={styles.listItem}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Price Engine<span>&#8964;</span>
              <ul className={styles["dropdown"]}>
                <div className={styles["dropdown-container"]}>
                  {priceEngineList?.map((item, index) => (
                    <li
                      className={styles["dropdown-item"]}
                      key={index + item?.id}
                      onClick={handleItemClick}
                    >
                      <Link
                        href={item.path}
                        className={styles["dropdown-item-detail"]}
                      >
                        <div className={styles["dropdown-item-detail-img"]}>
                          <Image
                            height={18}
                            width={24}
                            alt={item?.title}
                            src={item?.img}
                          />
                        </div>
                        <div className={styles["dropdown-item-detail-content"]}>
                          <p className={styles["dropdown-item-detail-title"]}>
                            {item?.title}
                          </p>
                          <p className={styles["dropdown-item-detail-des"]}>
                            {item?.description}
                          </p>
                        </div>
                      </Link>
                    </li>
                  ))}
                </div>
              </ul>
            </li>
            <li className={styles.listItem}>
              <Link href={"our-associations"}>Our Associations</Link>
            </li>
          </ul>
        </div>
        <div className={styles.buttonDiv}>
          <Link
            target="blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLScUKXrtoPaq2W2ag_cyszvEQkfs8ou4RV-BXd93rJl9E48y0g/viewform?usp=sf_link"
          >
            <Button text="We Are Hiring" classname="button-wrapper" />
          </Link>
        </div>
        <div onClick={() => setShowMenu(showMenu === true ? false : true)}>
          {/* <p className={styles.more}>=</p> */}
          {/* <p style={{color:'white', position:'absolute', top:20, right:30}}>=</p> */}
          <img src="nav.svg" alt="more" className={styles["more"]} />
        </div>
      </div>

      <nav role="navigation" className={styles["mobile-navigation"]}>
        <div
          id={`${styles["menuToggle"]}`}
          onClick={() => setShowMenu(!showMenu)}
        >
          {/* <form> */}
          <label htmlFor="hamburger">Menu</label>
          <input
            type="checkbox"
            checked={showMenu}
            onChange={(e) => {}}
            id="hamburger"
            name="hamburger"
            aria-labelledby="hamburger"
          />
          <span></span>
          <span></span>
          <span></span>
          {/* </form> */}
          <ul id={styles["menu"]}>
            <Link href="/about-us">
              <li>
                <button>About Us</button>
              </li>
            </Link>
            <Link href="/b2b-stack">
              <li>
                <button>B2B Stack</button>
              </li>
            </Link>
            <Link href="/b2c-d2c-stack">
              <li>
                <button>B2C & D2C Stack</button>
              </li>
            </Link>
            <Link href="/social-commerce">
              <li>
                <button>Social Commerce Stack</button>
              </li>
            </Link>
            <Link href="/generative-ai">
              <li>
                <button>Generative AI</button>
              </li>
            </Link>
            <Link href="/product-stack">
              <li>
                <button>Headless Solution</button>
              </li>
            </Link>
         
            <Link href="/commerce-apis">
              <li>
                <button>Commerce Apis</button>
              </li>
            </Link>
            <Link href="/oms-solutions">
              <li>
                <button>Order Management Solution</button>
              </li>
            </Link>
            <Link href="/eTail-data-lake">
              <li>
                <button>eTail Data Lake</button>
              </li>
            </Link>
            <Link href="/headless-price-engine-ai">
              <li>
                <button> Headless Price Engine</button>
              </li>
            </Link>
            <Link href="/trend-analyzer">
              <li>
                <button>Trend Analyzer</button>
              </li>
            </Link>
            <Link href="/our-associations">
              <li>
                <button>Our Associations</button>
              </li>
            </Link>
            <Link
              target="blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLScUKXrtoPaq2W2ag_cyszvEQkfs8ou4RV-BXd93rJl9E48y0g/viewform?usp=sf_link"
            >
              <li>
                <button>We Are Hiring</button>
              </li>
            </Link>
          </ul>
        </div>

        <Link href={"/"} className={styles["mobile-logo"]}>
          <img src="logo.svg" alt="logo" style={{ width: 20, height: 20 }} />
          <span>SOCOMMERZ</span>
        </Link>
      </nav>

      {contactForm && (
        <Modal>
          <ContactForm />
        </Modal>
      )}
    </nav>
  );
};

export default Navbar;
